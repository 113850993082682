footer {
    display: flex;
    font-size: 3vw;
    justify-content: center;
    gap: 3vw;
    padding-top: 2%;
}
p {
    margin: 1.5em 0em;
}
.footer-text {
    font-size: 3.5vw;;
}
@media (min-width: 768px) {
    .footer-text {
        font-size: 1.5vw;
    }
}
@media (min-width: 768px) {
    footer {
        font-size: 14px;
        gap: 30vw;
        padding-top: 1%;
    }
}