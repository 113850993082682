@import url('https://fonts.googleapis.com/css2?family=M+PLUS+1p:wght@100;400&display=swap');
.about-me-container {
    font-family: 'M PLUS 1p', sans-serif;
    padding: 5%;
    color: black
}
@media (min-width: 768px){
    .about-me-container {
        padding: 0%;
        padding-bottom: 2%
    }
}
@import url('https://fonts.googleapis.com/css2?family=Mitr:wght@400;600&display=swap');
.about-me-title-text {
    font-family: 'Mitr', sans-serif;
    padding: 1% 3%;
    display: flex;
    font-size: 10vw;
}
@media (min-width: 768px){
    .about-me-title-text {
        font-size: 5vw;
    }    
}
.about-me-text {
    font-size: 1.1em;
    padding: 5%;
}
@media (min-width: 768px){
    .about-me-brand-statement {
        font-size: 1.5vw;
        width: 75%;
        padding: 5% 0% 5% 20%;
    }
}
