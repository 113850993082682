.projects-container {
    background-image: linear-gradient(270deg, #04ADBF, #013F58);
    padding: 5%;
}
@media (min-width: 768px){
    .projects-container {
        padding: 0%;
        padding-bottom: 2%
    }
}
@media (min-width:768px){
    
}
@import url('https://fonts.googleapis.com/css2?family=Mitr:wght@400;600&display=swap');
.project-title {
    font-family: 'Mitr', sans-serif;
    padding: 1% 3%;
    color: whitesmoke;
    font-size: 10vw;
    display: grid;
    justify-items: start;
}
@media (min-width: 768px){
    .project-title {
        font-size: 5vw;
    }    
}
.card-button-container {
    display: flex;
    justify-content: space-around;
}
@media (min-width:768px){
    .project-card-container{
        display: grid;
        grid-template-columns: auto auto auto;
    }
}
@media (min-width:768px){
    div.card {
        height: 510px;
    }
}
.card-text {
    font-size: 1.1em;
}