@media (min-width:768px) {
    p {
        font-size: 24px;
    }
}
@media (min-width:768px){
    .dots {
        margin: 0;
    }
}
.skills-container {
    background-image: linear-gradient(2000deg, #2A4B6E, #7196C2);
    padding: 5% 5% 5% 3%;
    color: whitesmoke;
}
@media (min-width:768px){
    .skills-container{
        padding: 0%;
    }
}
.skills-group-title {
    display: flex;
    justify-content: center;
    padding: 1% 3%;
}
@import url('https://fonts.googleapis.com/css2?family=Mitr:wght@400;600&display=swap');
.skills-title {
    font-family: 'Mitr', sans-serif;
    padding: 1% 3%;
    color: whitesmoke;
    font-size: 10vw;
    display: flex;
}
@media (min-width: 768px){
    .skills-title {
        font-size: 5vw;
        padding: 1% 1.5% 0% 1.5%;
    }    
}
.skills-icon-container {
    display: flex;
    justify-content: space-around;
}
.icon-image {
    height: 50px;
    width: 50px;
}
@media (min-width:768px){
    .icon-image{
        height: 100px;
        width: 100px;
    }
}
@media (min-width:768px) {
    .skills-group-title {
        font-size: 36px;
        text-decoration: underline;

    }
    
}
.skill {
    font-size: 1.1em;
}