.social-icon-container {
    display: flex;
    justify-content: space-around;
}
.social-icon {
    width: 50px; 
    height: 50px; 
}
@media (min-width: 768px){
    .social-icon {
        height: 75px;
        width: 75px;
    }
}